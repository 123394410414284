/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import 'intl';
import React, { useState, Fragment, useRef, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { getCurrentLangKey } from 'ptz-i18n';
import styled from 'styled-components';
import ReactAudioPlayer from 'react-audio-player';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { isSafari } from 'react-device-detect';

import trackEvent from './analytics';
import Landscape from '../images/landscape.jpg';
import Rotate from '../images/rotate.png';
import Logo from '../images/logo-app.png';
import Texture from '../images/purty_wood.png';
import Voice1 from '../images/voice-1.png';
import Voice2 from '../images/voice-2.png';
import CGU from './cgu';
import CGUPdf from '../pdf/210413_CGU_COOKIES_POLICY_PERSONAL_DATA_FINDIETOU_version_def_.pdf';
// import CGUPdf from '../pdf/210413_CGU_COOKIES POLICY_PERSONAL DATA_FINDIETOU.pdf';
// import DPN from '../pdf/210413_DATA PROTECTION NOTICE_FINDIETOU.pdf';
import DPN from '../pdf/210413_DATA_PROTECTION_NOTICE_FINDIETOU_version_Def.pdf';
// import DPN from '../pdf/210413_DATA_PROTECTION_NOTICE_FINDIETOU_revu_F003.pdf';
// import CookiePolicy from '../pdf/210413_COOKIES POLICY_FINDIETOU.pdf';
import CookiePolicy from '../pdf/210413_COOKIES_POLICY_FINDIETOU_Version_def.pdf';
// import CookiePolicy from '../pdf/210413_COOKIES_POLICY_FINDIETOU_rev_F003.pdf';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

const ViewBackground = styled.div`
  display: none;
  background-image: url(${Landscape});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${device.laptop} {
    display: block;
  }
`;

const ViewPortraitWarning = styled.div`
  position: absolute;
  z-index: 1060;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  display: none;
  text-align: center;
  background: #fff;
  opacity: 1;

  @media (orientation: portrait) {
    display: flex;
  }

  @media ${device.laptop} {
    display: none;
  }
`;

const ViewMain = styled.main`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  display: flex;
`;

const ViewContent = styled.section`
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  background: #fff;
  animation: fadeIn;
  animation-duration: 2s;

  @media ${device.laptop} {
    transform: scale(1);
    width: 740px;
    margin: auto;
    min-height: 415px;
    max-height: 415px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 80px #0b1759;
  }

  @media ${device.laptopL} {
    transform: scale(1.4);
  }

  @media ${device.desktop} {
    transform: scale(1.7);
  }
`;

const ViewLogo = styled.div`
  position: absolute;
  display: block;
  top: 5px;
  left: 5px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  padding: 10px 7px 16px 7px;
  animation: bounceIn;
  animation-duration: 2s;

  img {
    width: 52px;
    height: auto;
  }
`;

const ViewHome = styled.span`
  position: absolute;
  top: 70px;
  left: 25px;
  border-radius: 200px;
  background: #ffc107;
  height: 24px;
  text-align: center;
  width: 24px;
  font-size: 0.9rem;
  animation: bounceIn;
  animation-duration: 2s;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  i,
  svg {
    color: #222;
    height: auto;
  }
`;

const ViewImg = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 3rem;
`;

const ViewRotate = styled.img`
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
`;

export const ViewPlayer = styled.div`
  display: flex;
  flex: 0 0 auto;
  min-width: 100%;
  justify-items: flex-end;
  background: #f1f3f4;

  .react-audio-player {
    width: 100%;
  }
`;

const ViewLang = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: stretch;

  a {
    display: flex;
  }

  button {
    border-radius: 0;
  }
`;

const ViewButtonDioula = styled(Button)`
  background-color: #8fa824;
  border-color: #8fa824;

  &:hover {
    background-color: #6e821b;
    border-color: #6e821b;
  }
`;

export const ViewButtonNext = styled(Button)`
  position: absolute;
  right: 10px;
  top: 40%;
  animation: bounceIn;
  animation-duration: 4s;
`;

export const ViewButtonPrevious = styled(Button)`
  position: absolute;
  left: 10px;
  top: 40%;
  animation: bounceIn;
  animation-duration: 4s;
`;

export const ViewShares = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  border-radius: 5px;
  overflow: hidden;
  animation: bounceIn;
  animation-duration: 2s;
`;

export const ViewChoices = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  padding: 2.5rem 1.5rem 2rem 7.5rem;
  animation: fadeIn;
  animation-duration: 2s;

  a {
    display: flex;
    flex: 0 0 auto;
    height: 45px;
  }
`;

export const ViewModuleName = styled.div`
  position: absolute;
  width: 100%;
  bottom: 50px;
  margin: auto;
  display: flex;
  flex: 1 0 100%;
  flex-flow: column nowrap;
  align-items: center;
  overflow-y: auto;
  padding: 1.5rem;
  animation: bounceIn;
  animation-duration: 2s;
`;

export const ViewChoice = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  background: url(${Texture});
  margin: 0.15rem 0;
  border-radius: 10px;
  padding: 0.4rem 0.5rem 0.4rem 1.4rem;
  box-shadow: inset 0 0 40px #2f2211;
  font-weight: bold;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  color: #fff;
  border: 0;
  transform: rotate(-2deg);
  text-shadow: 2px 2px #000;
`;

export const ViewImgTop = styled.img`
  position: absolute;
  width: 30px;
  margin-top: 10px;
  margin-left: -2.5rem;
`;

export const ViewModalBody = styled(ModalBody)`
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
`;

export const ViewBreadcrumbs = styled.ul`
  position: absolute;
  margin: 0 auto;
  width: 135px;
  bottom: 58px;
  left: 50%;
  margin-left: -75px;
  display: inline-block;
  z-index: 1200;
  border: 1px solid #222;
  border-radius: 10px;
  list-style: none;
  background: url(${Texture});
  padding: 0.3rem 0.5rem;
  box-shadow: inset 0 0 40px #2f2211;
`;

export const Breadcrumb = styled.li`
  float: left;
  border: 1px solid #111;
  background: #fff;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  border-radius: 100px;

  ${(props) => props.active && 'border-color: #111; background: #ffc107'}
`;

const ViewModal = styled(Modal)`
  max-width: 96%;
  max-height: 100%;
  margin: 1rem auto;
  font-size: 0.9rem;

  @media ${device.laptop} {
    max-width: 600px;
    max-height: 450px;
    margin: 3rem auto;
  }
`;

const ViewModalHeader = styled(ModalHeader)`
  padding: 0.5rem 1rem;
  text-align: center;

  h5 {
    font-size: 1rem;
  }
`;

const ViewPolicy = styled.div`
  position: absolute;
  right: 5px;
  bottom: 58px;
  display: flex;
`;

const ViewLink = styled.a`
  cursor: pointer;
  display: block;
  font-size: 0.6rem;
  background: #4d2003;
  color: #eee;
  padding: 0 0.4rem;
  margin: 0 0.1rem;
  border-radius: 20px;
  line-height: 1.3;

  &:hover {
    color: #fff;
  }
`;

const ViewParagraphValidation = styled.p`
  text-align: center;
`;

export const Player = (props) => {
  const url = typeof window !== 'undefined' ? location.pathname : '/';
  const langKey = getCurrentLangKey(['di', 'fr', 'ba'], 'di', url);

  var locationPath = typeof window !== 'undefined' && window.location.pathname;

  var pathArray = typeof window !== 'undefined' && locationPath.split('/');
  var secondLevelLocation = typeof window !== 'undefined' && pathArray[2];

  // const audioRef = useRef(null);
  // useEffect(() => {
  //   // When the component mounts, play the audio
  //   if (audioRef.current) {
  //     // audioRef.current.audioEl.current.muted = true;
  //     // audioRef.current.audioEl.current?.addEventListener('canplaythrough', () => {
  //     //   audioRef.current.audioEl.current.play();
  //     // });
  //     // After 2 seconds, unmute the audio
  //     // var unmuteTimeout = setTimeout(() => {
  //     //   audioRef.current.audioEl.current.play();
  //     //   audioRef.current.audioEl.current.muted = false;
  //     // }, 2000);
  //   }

  //   // Cleanup the timeout to prevent memory leaks
  //   // return () => clearTimeout(unmuteTimeout);
  // }, []);

  return (
    <ViewPlayer>
      {!props.isIndex && (
        <ViewLang>
          <Link
            to={
              secondLevelLocation !== undefined
                ? `/di/${secondLevelLocation}`
                : '/di/'
            }
          >
            <ViewButtonDioula size="sm" color="success">
              {langKey === 'di' ? (
                <img src={Voice2} width="24" alt="Dioula" />
              ) : (
                <img src={Voice1} width="24" alt="Dioula" />
              )}
            </ViewButtonDioula>
          </Link>
          <Link
            to={
              secondLevelLocation !== undefined
                ? `/fr/${secondLevelLocation}`
                : '/fr/'
            }
          >
            <Button size="sm" color="info">
              {langKey === 'fr' ? (
                <img src={Voice2} width="24" alt="Dioula" />
              ) : (
                <img src={Voice1} width="24" alt="Dioula" />
              )}
            </Button>
          </Link>
          <Link
            to={
              secondLevelLocation !== undefined
                ? `/ba/${secondLevelLocation}`
                : '/ba/'
            }
          >
            <Button size="sm" color="ba-info">
              {langKey === 'ba' ? (
                <img src={Voice2} width="24" alt="Dioula" />
              ) : (
                <img src={Voice1} width="24" alt="Dioula" />
              )}
            </Button>
          </Link>
        </ViewLang>
      )}
      {props.isQuizz || isSafari ? (
        <ReactAudioPlayer id="audio" src={props.sound} controls volume={0.5} />
      ) : (
        <ReactAudioPlayer
          id="audio"
          src={props.sound}
          // ref={audioRef}
          autoPlay
          controls
          volume={0.5}
          // muted={true}
        />
      )}
    </ViewPlayer>
  );
};

export const ShareButtons = () => (
  <ViewShares>
    <FacebookShareButton
      url={'https://findietou.com/'}
      title={
        "Découvre l’application FinDiETou et ses modules d'éducation financière pour t’aider à développer ton activité !"
      }
      hashtag="#FinDiETou"
      quote={
        'L’application FinDiETou te donne des astuces et conseils pratiques pour développer ton activité, augmenter tes revenus, et épargner ton argent pour tes projets professionnels et personnels. Bonne formation !'
      }
      onClick={() => {
        trackEvent('Share Buttons', 'User clicked the Facebook Button', 'yes');
      }}
    >
      <FacebookIcon size={32} />
    </FacebookShareButton>
    <TwitterShareButton
      url={'https://findietou.com/'}
      title={
        "Découvre l’application FinDiETou et ses modules d'éducation financière pour t’aider à développer ton activité !"
      }
      hashtag="#FinDiETou"
      onClick={() => {
        trackEvent('Share Buttons', 'User clicked the Twitter Button', 'yes');
      }}
    >
      <TwitterIcon size={32} />
    </TwitterShareButton>
    <WhatsappShareButton
      url={'https://findietou.com/'}
      title={
        "Découvre l’application FinDiETou et ses modules d'éducation financière pour t’aider à développer ton activité !"
      }
      separator="-"
      onClick={() => {
        trackEvent('Share Buttons', 'User clicked the Whatsapp Button', 'yes');
      }}
    >
      <WhatsappIcon size={32} />
    </WhatsappShareButton>
  </ViewShares>
);

export const Breadcrumbs = (props) => (
  <ViewBreadcrumbs>
    <Breadcrumb active={props.active === 1 && true} />
    <Breadcrumb active={props.active === 2 && true} />
    <Breadcrumb active={props.active === 3 && true} />
    <Breadcrumb active={props.active === 4 && true} />
    <Breadcrumb active={props.active === 5 && true} />
    <Breadcrumb active={props.active === 6 && true} />
    <Breadcrumb active={props.active === 7 && true} />
    <Breadcrumb active={props.active === 8 && true} />
  </ViewBreadcrumbs>
);

const Layout = ({ children, maintenance }) => {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);

  const storeChoice = () => {
    localStorage.setItem('CGU', 'accepted');
  };

  return (
    <Fragment>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/icon-path-logo.png" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      </Helmet>

      {typeof window !== 'undefined' && localStorage.getItem('CGU') != 'accepted' && (
        <ViewModal
          isOpen={modal}
          scrollable
          toggle={toggle}
          keyboard={false}
          backdrop="static"
          size="lg"
        >
          <ViewModalHeader>
            CONDITIONS GÉNERALES D'UTILISATION, POLITIQUE COOKIES ET PROTECTION DES
            DONNÉES A CARACTÈRE PERSONNEL DU SITE FINDIETOU
          </ViewModalHeader>
          <ModalBody>
            <CGU />
            <ViewParagraphValidation>
              <strong>
                J’accepte les présentes CGU (Conditions Générales d'Utilisation) pour
                utiliser l’application FINDIETOU pendant la phase pilote
              </strong>
            </ViewParagraphValidation>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              block
              size="sm"
              onClick={() => {
                toggle();
                storeChoice();
              }}
            >
              <i className="fa fa-check" /> Accepter
            </Button>
          </ModalFooter>
        </ViewModal>
      )}

      <ViewBackground />

      {/* <ViewPortraitWarning>
        <ViewImg src={Logo} alt="FinDiETou" />
        <p>Mode Portrait non supporté</p>
        <ViewRotate src={Rotate} alt="Rotate" />
        <p>
          <strong>Changer l'orientation de l'appareil</strong>
        </p>
      </ViewPortraitWarning> */}

      <ViewMain>
        <ViewContent>
          <ViewLogo>
            <Link to="/">
              <img src={Logo} alt="FinDiETou" />
            </Link>
          </ViewLogo>

          {typeof window !== 'undefined' && window.location.pathname !== '/' && (
            <ViewHome>
              <Link to="/">
                <i className="fa fa-home" />
              </Link>
            </ViewHome>
          )}

          {!maintenance && children}
          <ViewPolicy>
            <ViewLink href={CGUPdf} target="_blank" rel="noopener noreferrer">
              CGU
            </ViewLink>
            <ViewLink href={CookiePolicy} target="_blank" rel="noopener noreferrer">
              Politique cookies
            </ViewLink>
            <ViewLink href={DPN} target="_blank" rel="noopener noreferrer">
              DPN
            </ViewLink>
          </ViewPolicy>
        </ViewContent>
      </ViewMain>
    </Fragment>
  );
};

export default Layout;
