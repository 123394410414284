import React, { Fragment } from 'react';
import styled from 'styled-components';

// import pdf from '../pdf/210413_CGU_COOKIES POLICY_PERSONAL DATA_FINDIETOU.pdf';
import pdf from '../pdf/210413_CGU_COOKIES_POLICY_PERSONAL_DATA_FINDIETOU_version_def_.pdf';

const ViewParagraph = styled.p`
  margin-bottom: 0.5rem;
`;

const ViewH2 = styled.h2`
  font-size: 1.1rem;
  margin-top: 1.5rem;
`;

const ViewH3 = styled.h3`
  font-size: 1rem;
  margin-top: 1.5rem;
`;

const ViewH4 = styled.h4`
  font-size: 0.9rem;
  margin-top: 1.5rem;
`;

const CGU = () => (
  <Fragment>
    <ViewParagraph>
      <strong>
        <i className="fa fa-download" /> <a href={pdf}>Télécharger ce document</a>
      </strong>{' '}
      - Dernière modification : 05/12/2023
    </ViewParagraph>
    <ViewH2>PRÉSENTATION DU SITE ET DES SERVICES</ViewH2>
    <ViewParagraph>
      Le Site FINDIETOU propose une plateforme dédiée aux Commerçants détaillants des
      marchés en Côte d'Ivoire, et a pour vocation d'apporter du contenu d'éducation
      financière.
    </ViewParagraph>
    <ViewParagraph>
      Le Site est édité́ par la société́ BICICI, dont les coordonnées sont détaillées à
      l’Article 13 (article "Informations légales") ci-après (« FINDIETOU » ou «
      BICICI »).
    </ViewParagraph>
    <ViewParagraph>
      Le Site offre à chaque Utilisateur, sous réserve du respect des CGU (Conditions
      Générales d’Utilisation), l’accès à certains services en ligne (les « Services
      »), tel que détaillés à l’Article 2 (article "Fonctionnalités du Service").
    </ViewParagraph>
    <ViewParagraph>
      L'utilisation du Site par un Utilisateur est régie par les conditions
      d’utilisation du Site en vigueur (les « CGU » (Conditions Générales
      d’Utilisation)) et suppose leur acceptation pleine et entière.
    </ViewParagraph>
    <ViewParagraph>
      L’Utilisateur du Site et toute personne accédant au Site ou à ses Services
      s’engage, à chacune de ses visites du Site, à respecter les CGU (Conditions
      Générales d’Utilisation), lesquelles sont applicables à l’ensemble des Services
      disponibles sur le Site.
    </ViewParagraph>
    <ViewH2>1. DEFINITIONS</ViewH2>
    <ViewParagraph>
      <strong>« FINDIETOU »</strong> désigne la web application d'éducation
      financière à destination des Commerçants détaillants qui travaillent sur les
      marchés en Côte d'Ivoire mais aussi de toute personnes partout ailleurs.
    </ViewParagraph>
    <ViewParagraph>
      <strong>« Données Utilisateurs »</strong> désigne toutes les données et
      informations relatives aux Utilisateurs, transmises et analysées par FINDIETOU
      lors de l’utilisation du Service par les Utilisateurs, y compris ses Données à
      caractère Personnel dans le cadre de l’Article 8 (article "Données personnelles
      des Utilisateurs") des CGU (Conditions Générales d’Utilisation).
    </ViewParagraph>
    <ViewParagraph>
      <strong>« Service »</strong> désigne tout ou partie des fonctionnalités
      utilisables et proposées par FINDIETOU sur le Site, telles que décrites à
      l'Article 2 (article "Fonctionnalités du Service") des CGU.
    </ViewParagraph>
    <ViewParagraph>
      <strong>« Site »</strong> désigne l'interface logicielle, le site Internet,
      accessible à l’adresse suivante findietou.com, application mobile ou tout autre
      service de communication électronique servant de support au Service.
    </ViewParagraph>
    <ViewParagraph>
      <strong>« Tiers »</strong> désigne toute personne physique ou morale tiers à
      FINDIETOU, à un Utilisateur, à BICICI.
    </ViewParagraph>
    <ViewParagraph>
      <strong>« Utilisateur »</strong> désigne toute personne accédant au Site et
      utilisant ses fonctionnalités, mises à disposition des Utilisateurs via le Site
      par FINDIETOU par FINDIETOU
    </ViewParagraph>
    <ViewH2>2. FONCTIONNALITÉS DU SERVICE </ViewH2>
    <ViewParagraph>
      Afin de bénéficier pleinement du Site, l’Utilisateur doit disposer d’un
      appareil de type téléphone intelligent ou smartphone, tablette, ou ordinateur.
      Lors de la première connexion, l'Utilisateur devra disposer d'un accès
      suffisant aux données internet; il pourra par la suite télécharger le contenu
      de l'application sur son appareil. L'appareil demeure à la charge de
      l’Utilisateur, de même que les frais de communication induits par l’utilisation
      du Site.
      <br />
      Dans le cadre du Service, l’Utilisateur peut : accéder et utiliser le Site
    </ViewParagraph>
    <ViewH2>3. INSCRIPTION ET ACCÈS AU SERVICE </ViewH2>
    <ViewParagraph>
      Le Service est proposé́ aux Utilisateurs qui ont accepté́ d’y participer.
      L'utilisation du Service par les Utilisateurs est soumise à l'acceptation et au
      respect des CGU (Conditions Générales d’Utilisation).
    </ViewParagraph>
    <ViewH2>4. PROPRIÉTÉ INTELLECTUELLE ET RESPONSABILITÉ DES CONTENUS</ViewH2>
    <ViewH3>4.1 DROITS DETENUS PAR FINDIETOU</ViewH3>
    <ViewParagraph>
      FINDIETOU fournit à l’Utilisateur des ressources et contenus de toute nature
      pouvant inclure notamment, sans s’y limiter : <br />
      (i) des graphismes, données, logos, signes distinctifs, photographies, textes,
      vidéos, animations, interfaces, bases de données, etc.
      <br /> (ii) les marques appartenant à FINDIETOU (ensemble des « Contenus
      FINDIETOU »).
    </ViewParagraph>
    <ViewParagraph>
      Les Contenus FINDIETOU sont édités par FINDIETOU et constituent la propriété́
      exclusive de FINDIETOU et de son titulaire la BICICI ; ayant autorisé́ FINDIETOU
      à les utiliser.
    </ViewParagraph>
    <ViewParagraph>
      Le Site et l’ensemble de ses éléments sont protégés par le droit d’auteur, le
      droit des marques et, de manière plus générale, par la législation relative à
      la propriété́ intellectuelle.
    </ViewParagraph>
    <ViewParagraph>
      L’Utilisateur s’interdit en conséquence de représenter, modifier, copier et
      reproduire, de manière complète ou partielle les Contenus FINDIETOU sans
      l’autorisation écrite et préalable de la BICICI, et à l’exclusion de tout usage
      lucratif ou commercial, que ce soit à titre gratuit ou onéreux ou au bénéfice
      d’un tiers.
    </ViewParagraph>
    <ViewH3>4.2 CONTENUS EXTERNES</ViewH3>
    <ViewParagraph>
      Le Site est susceptible d’inclure des liens vers des applications, sites
      Internet ou sources externes. FINDIETOU ne peut contrôler ces applications,
      sites Internet et sources externes, et ne saurait en conséquence être tenu pour
      responsable de la mise à disposition de liens vers ces applications, sites
      Internet et sources externes, notamment quant à leur contenu, opinions,
      recommandations, publicité, produits ou tout autre service disponible sur ou à
      partir de ces applications, sites Internet ou sources externes. <br /> Il
      incombe à l’Utilisateur de prendre les précautions nécessaires pour s’assurer
      de l’absence de virus informatique sur les applications, sites Internet et
      sources externes visités.
    </ViewParagraph>
    <ViewH3>4.3 EVALUATIONS</ViewH3>
    <ViewParagraph>
      Les Utilisateurs peuvent laisser des évaluations dans chacun des 3 modules de
      FINDIETOU (vote en cliquant sur 3 niveaux de smileys). Aucune contrepartie
      n'est accordée aux Utilisateurs ou à FINDIETOU en échange de leurs évaluations.
      <br />
      Les évaluations partagées dans FINDIETOU ne sont pas publiées publiquement et
      ont pour but principal, la prise en charge de feedbacks et l'amélioration du
      produit. Elles peuvent être utiliser dans le cadre des actions visant à
      l’amélioration de l’interaction entre FINDIETOU et l’utilisateur.
    </ViewParagraph>
    <ViewH2>5. OBLIGATIONS DE L'UTILISATEUR</ViewH2>
    <ViewParagraph>
      Les frais d’accès et d’utilisation du réseau de télécommunication pour accéder
      au Site sont à la charge de l’Utilisateur, selon les modalités fixées par ses
      fournisseurs d’accès et opérateurs de communications électroniques.
    </ViewParagraph>
    <ViewParagraph>
      L’Utilisateur s’engage à respecter les dispositions des CGU (Conditions
      Générales d’Utilisation) ainsi que l’ensemble des lois et règlements
      applicables lors de l’utilisation du Site.
    </ViewParagraph>
    <ViewParagraph>
      Il est rappelé que le fait d’accéder ou de se maintenir frauduleusement dans un
      système informatique, d’entraver ou de fausser le fonctionnement d’un tel
      système, d’introduire ou de modifier frauduleusement des données dans un
      système informatique constituent des délits passibles de sanctions pénales et
      justifient une suspension de la fourniture de tout ou partie du Service
      conformément à l’Article 10 (article « Modification des CGU ») des CGU
      (Conditions Générales d’Utilisation) sans préjudice d’éventuelles poursuites
      judiciaires.
    </ViewParagraph>
    <ViewParagraph>
      En particulier, l’Utilisateur s’engage à :<br />
      (i) ne pas compromettre, endommager, désactiver, surcharger, perturber ou
      attenter à la sécurité́ ou au fonctionnement du Site ou des systèmes
      d’information et d’exploitation de FINDIETOU ou de ses éventuels prestataires,
      <br />
      (ii) ne pas entraver le fonctionnement habituel ou effectuer une utilisation
      frauduleuse du Site, de quelque manière et sous quelque forme que ce soit ;
      <br />
      (iii) ne pas utiliser ou permettre l’utilisation du Site de manière abusive ou
      illicite, à des fins illicites frauduleuses ou malveillantes ; et notamment,
      sans que cela soit limitatif en piratant le Site ou en introduisant un code
      malveillant, y compris des virus, ou des données nuisibles dans le Site, en
      utilisant tout système, dispositif ou programme pour extraire des données ou
      des informations du Site ou des serveurs de FINDIETOU, en envoyant ou en
      stockant des documents contenant des virus, vers, chevaux de Troie ou autres
      codes, fichiers, scripts, agents ou programmes informations nuisibles, en
      interférant ou en portant atteinte à l’intégrité́ ou à la performance du Site et
      des données qu’il contient ou en tentant d’obtenir un accès non autorisé au
      Site, à ses systèmes ou réseaux associés.
    </ViewParagraph>
    <ViewH2>6. OBLIGATIONS DE FINDIETOU</ViewH2>
    <ViewParagraph>
      L'Utilisateur reconnait que le Site est susceptible de connaitre des évolutions
      et qu’à ce titre, tous défauts et défaillances, notamment techniques, seront
      corrigés par FINDIETOU. L'Utilisateur reconnait et accepte que le Site puisse
      être momentanément indisponible, en particulier, pour des raisons de
      maintenance et d'évolution des Services dans les conditions décrites à
      l'Article 7 (article « Disponibilité́ du Service ») des CGU (Conditions
      Générales d’Utilisation).
      <br />
      FINDIETOU s’efforce d’assurer l’exactitude, l’exhaustivité́ et la mise à jour
      des informations diffusées sur le Site par lui, dont il se réserve le droit de
      modifier le contenu, à tout moment et sans préavis. FINDIETOU ne peut cependant
      en garantir l’exactitude, l’exhaustivité́, l’adéquation à un usage spécifique,
      la véracité́ ou l’absence de modification par un Tiers ou un Utilisateur. <br />
      Le Site est fourni sans garantie sur son bon fonctionnement concernant les
      équipements et environnements logiciels utilisés par les Utilisateurs.
      FINDIETOU ne saurait être tenu pour responsable en cas de détérioration des
      logiciels de l’Utilisateur et de tout piratage dont il serait victime du fait
      de l'utilisation du Site et qui ne serait pas directement imputable à une faute
      de FINDIETOU. <br />
      L'Utilisateur est informé que la connexion aux services s'effectue via le
      réseau Internet. Il est averti des aléas techniques qui peuvent affecter ce
      réseau et entrainer des ralentissements ou des indisponibilités rendant la
      connexion impossible. FINDIETOU ne saurait être responsable à l’égard des
      Utilisateurs de toute défaillance des lignes, des systèmes de communication ou
      de tout autre système nécessaire à l’utilisation du Site.
      <br />
      Les CGU (Conditions Générales d’Utilisation) énoncent l’intégralité́ des
      obligations et responsabilités de FINDIETOU, qui ne saurait être liées par
      aucune autre garantie expresse ou implicite à l’égard de l’Utilisateur.
    </ViewParagraph>
    <ViewH2>7. DISPONIBILITÉ DU SERVICE</ViewH2>
    <ViewParagraph>
      FINDIETOU s’efforce, dans la mesure du possible et dans les termes de son
      obligation de moyen, d’assurer à l’Utilisateur une accessibilité́ à tout moment
      au Site et aux Services ainsi qu’un niveau de fonctionnement et de
      disponibilité́ raisonnable du Site. L'exploitation du Site pourra être
      momentanément interrompue pour toute cause indépendante ou non de la volonté́ de
      FINDIETOU, et ce y compris en cas de force majeure, de maintenance, de mises à
      jour ou d'améliorations techniques, ou pour en faire évoluer son contenu et/ou
      sa présentation.
    </ViewParagraph>
    <ViewH2>8. DONNÉES PERSONNELLES DES UTILISATEURS</ViewH2>
    <ViewH3>8.1 DONNÉES À CARACTERE PERSONNEL</ViewH3>
    <ViewParagraph>
      Lors de l’utilisation du Site par les Utilisateurs, FINDIETOU propose de
      collecter certaines de leurs données à caractère personnel. Si les Utilisateurs
      le souhaitent, ils peuvent communiquer leur nom et leur numéro de téléphone
      grâce à un formulaire afin d’indiquer qu’ils souhaitent être contactés pour
      s’inscrire aux prochaines Matinales de formation de FINDIETOU et pour recevoir
      des informations relatives à la mise à jour du contenu de formation dans
      l’Application.{' '}
    </ViewParagraph>
    <ViewParagraph>
      La BICICI agira en tant que responsable du traitement des données personnelles
      collectées, stockées et traitées dans le cadre de la présente offre ;{' '}
    </ViewParagraph>
    <ViewParagraph>
      Les données personnelles seront traitées uniquement dans le but de fournir le
      service "FINDIETOU" auquel l’Utilisateur souscrit volontairement, afin de
      permettre d'accéder au service et de lui envoyer des notifications
      électroniques liées à l’usage du service.
    </ViewParagraph>
    <ViewParagraph>
      En utilisant "FINDIETOU", l’Utilisateur consent à la collecte, au traitement et
      à l'utilisation de ses données personnelles conformément aux termes des
      présentes conditions d’utilisation et aux obligations légales en matière de
      protection des données.
    </ViewParagraph>
    <ViewParagraph>
      Les données personnelles des Utilisateurs seront conservées aussi longtemps que
      nécessaire pour la fourniture du service et conformément à la législation
      applicable.
    </ViewParagraph>
    <ViewParagraph>
      La Banque n'autorisera pas le transfert des données personnelles des
      Utilisateurs à des tiers sans leurs consentements explicites, sauf si la
      législation en vigueur l'exige ou le permet.
    </ViewParagraph>
    <ViewParagraph>
      La Banque s'engage à mettre en place des mesures de sécurité appropriées pour
      protéger les données personnelles des Utilisateurs contre tout accès non
      autorisé, toute divulgation ou tout traitement non autorisé, conformément aux
      obligations légales applicables.
    </ViewParagraph>
    <ViewParagraph>
      Conformément à la loi n°2013-450 du 19 juin 2013 relative à la protection des
      données à caractère personnel, les Utilisateurs disposent de droits y compris
      le droit d'accès, de rectification, de suppression, de limitation du traitement
      et de portabilité des données. Les Utilisateurs peuvent exercer ces droits en
      adressant un courriel à l’adresse mail suivant du Correspondant des données
      personnelles ou DPO :paul.kouame@bicici.ci ou en se rendant à notre siège
      social sis à Abidjan Plateau, Avenue Franchet d’Esperey, 01 BP 1298 Abidjan 01
      adresse du siège social. Pour plus d’informations, vous pouvez consulter et
      télécharger notre Notice de Protection des Données à caractère Personnel (DPN)
      sur l’application https://findietou.com/.
    </ViewParagraph>
    <ViewH3>8.2 POLITIQUE COOKIES</ViewH3>
    <ViewParagraph>
      La BICICI, est particulièrement attentive à vous délivrer le meilleur service
      possible et nous souhaitons préserver la confiance que vous nous témoignez.
      Dans ce cadre, nous avons adopté́ des principes forts afin d’assurer la
      protection de vos données.
    </ViewParagraph>
    <ViewParagraph>
      C'est pourquoi nous souhaitons vous fournir des informations transparentes sur
      la manière dont nous plaçons, utilisons et stockons des cookies sur votre
      appareil lorsque vous consultez nos sites ou applications (ci-après le « Site »
      et/ou l’«Application » FINDIETOU) et vous présenter les options qui vous
      permettent de les gérer et les supprimer.
    </ViewParagraph>
    <ViewH4>1. Qu’est-ce qu’un cookie ? </ViewH4>
    <ViewParagraph>
      Les cookies sont de petits fichiers texte, image ou logiciel qui peuvent être
      déposés et/ou lus sur votre appareil lorsque vous accédez à notre Site et/ou à
      l’Application. Le mot « appareil », lorsqu'il est utilisé́ dans la présente
      Politique Cookies, désigne notamment les ordinateurs, les téléphones
      intelligents, les tablettes et tout autre appareil utilisé pour accéder à
      Internet.
    </ViewParagraph>
    <ViewParagraph>
      Les cookies peuvent être (i) de session, ce qui signifie qu’ils sont supprimés
      de votre terminal une fois la session et le navigateur fermes ; ou (ii)
      persistants, ce qui signifie qu’ils seront stockés sur votre terminal jusqu’à
      leur suppression.
    </ViewParagraph>
    <ViewH4>
      2. Quels types d’information peuvent être stockés dans un cookie ?
    </ViewH4>
    <ViewParagraph>
      Les informations stockées par les cookies déposés sur votre appareil peuvent
      avoir trait aux éléments suivants, dans la limite de leur durée de conservation
      :
    </ViewParagraph>
    <ul>
      <li>les pages web que vous avez visitées en utilisant cet appareil ; </li>
      <li>le type de navigateur que vous utilisez ;</li>
      <li>votre adresse IP ;</li>
      <li>
        et toute autre information que vous avez fournie sur notre Site/Application.
      </li>
    </ul>
    <ViewH4>
      3. Quel type de cookies utilisons nous ? Est-il possible de refuser
      l’installation de ces cookies ?
    </ViewH4>
    <ViewParagraph>
      Les cookies que nous utilisons sur ce Site/cette Application sont exclusivement
      des cookies qui ont pour seul but de permettre ou faciliter la communication
      par voie électronique ou qui sont strictement nécessaires au fonctionnement du
      Site/de l’Application.
    </ViewParagraph>
    <ViewParagraph>
      Les cookies strictement nécessaires que nous utilisons sur ce Site/cette
      Application peuvent notamment nous permettre de :
    </ViewParagraph>
    <ul>
      <li>
        suivre votre manière d’utiliser notre Site et/ou Application afin de
        l’améliorer, par exemple en optimisant ses performances techniques ou son
        ergonomie ;
      </li>
    </ul>
    <ViewParagraph>
      L’utilisation des cookies strictement nécessaire au bon fonctionnement du
      Site/de l’Application ne requiert pas votre consentement. C’est pourquoi aucun
      module de gestion de vos préférences en matière de cookies n’est disponible sur
      ce Site/cette Application.
    </ViewParagraph>
    <ViewH2>9. RÉSILIATION</ViewH2>
    <ViewParagraph>
      Les CGU (Conditions Générales d’Utilisation) sont constitutives d'un contrat
      conclu entre l’Utilisateur et FINDIETOU pour une durée indéterminée à compter
      de la date d'adhésion par l'Utilisateur aux CGU.
    </ViewParagraph>
    <ViewParagraph>
      Les présentes CGU (Conditions Générales d’Utilisation) régissent l'utilisation
      des Services FINDIETOU et du Site FINDIETOU. Les présentes CGU peuvent faire
      l’objet de révisions et de nouvelles conditions générales d'utilisation et de
      vente seront communiquées aux Utilisateurs aux fins d'acceptation.
    </ViewParagraph>
    <ViewParagraph>
      Les Utilisateurs peuvent mettre fin à tout moment à leur adhésion aux CGU en
      procédant à une demande d’effacement de leurs Données à caractère Personnel
      conformément à l’Article 8.1 (article "Données à caractère personnel") des CGU
      (Conditions Générales d’Utilisation) et en cessant toute utilisation du Site.
    </ViewParagraph>
    <ViewH2>10. MODIFICATION DES CGU (CONDITIONS GENERALES D’UTILISATION)</ViewH2>
    <ViewParagraph>
      FINDIETOU pourra modifier à tout moment et sans préavis les CGU (Conditions
      Générales d’Utilisation).
      <br />
      En cas de modification des CGU (Conditions Générales d’Utilisation), FINDIETOU
      indiquera sur le Site la nouvelle version des CGU (Conditions Générales
      d’Utilisation) à Accepter et la date de mise à jour des CGU.
    </ViewParagraph>
    <ViewParagraph>
      Sous réserve du paragraphe suivant, il appartient à l'Utilisateur de lire et
      d'Accepter la dernière version des CGU (Conditions Générales d’Utilisation).
      <br />
      Sans l'Acceptation de la nouvelle version des CGU (Conditions Générales
      d’Utilisation) proposée, l'Utilisateur n'aura plus accès aux Services.
    </ViewParagraph>
    <ViewH2>11. STIPULATIONS DIVERSES</ViewH2>
    <ViewH3>11.1 CONVENTION SUR LA PREUVE</ViewH3>
    <ViewParagraph>
      Les CGU (Conditions Générales d’Utilisation) sont fournies aux Utilisateurs sur
      un support durable sous la forme d’une image dans des conditions de sécurité́
      usuellement reconnues comme fiables. L’Utilisateur peut à tout moment effectuer
      une sauvegarde électronique ou une impression papier des CGU (Conditions
      Générales d’Utilisation). A ce titre, les CGU (Conditions Générales
      d’Utilisation) sont considérées comme preuve des communications et d’un accord
      intervenu entre l’Utilisateur et FINDIETOU. L’Utilisateur reconnait que la
      valeur probante de ce document ne pourra être remise en cause du simple fait de
      sa forme, notamment électronique.
    </ViewParagraph>
    <ViewH3>11.2 NON RENONCIATION</ViewH3>
    <ViewParagraph>
      Le fait pour FINDIETOU de ne pas exiger à un moment quelconque l’exécution
      complète d’une partie ou de l’ensemble des stipulations des CGU (Conditions
      Générales d’Utilisation) n’affectera en aucune façon le droit pour FINDIETOU de
      s’en prévaloir ultérieurement.
    </ViewParagraph>
    <ViewH3>11.3 NON VALIDITÉ PARTIELLE</ViewH3>
    <ViewParagraph>
      Si une partie quelconque des CGU (Conditions Générales d’Utilisation) devait
      être déclarée illégale, invalide ou inapplicable pour quelque raison que ce
      soit, le terme ou les termes en question seraient déclarés inexistants et les
      termes restants garderaient toute leur force et leur portée et continueraient à
      être applicables.
    </ViewParagraph>
    <ViewH2>12. LOI APPLICABLE ET JURIDICTION COMPÉTENTE</ViewH2>
    <ViewParagraph>
      Les CGU (Conditions Générales d’Utilisation) sont soumises à législation
      ivoirienne, à l’exclusion des règles et traites relatifs aux conflits de lois,
      et sous réserve des règles impératives du pays de résidence de l’Utilisateur.
    </ViewParagraph>
    <ViewParagraph>
      En cas de contestation portant sur la validité́, l’exécution, l’interprétation
      ou la formation des CGU (Conditions Générales d’Utilisation), l’Utilisateur
      peut contacter FINDIETOU par courrier recommandé avec accusé de réception aux
      coordonnées figurant à l’Article 13 (article "Informations légales") des CGU
      (Conditions Générales d’Utilisation).
    </ViewParagraph>
    <ViewParagraph>
      En ce cas, FINDIETOU et l’Utilisateur s’efforceront d’y apporter une solution
      amiable.
    </ViewParagraph>
    <ViewParagraph>
      Si aucune solution amiable n’a été́ trouvée à la suite d’une réclamation écrite
      de l'Utilisateur ou en l’absence de réponse de FINDIETOU dans le délai
      raisonnable d’un (1) mois, l'Utilisateur peut recourir au Tribunal de Commerce
      d’Abidjan.
    </ViewParagraph>
    <ViewParagraph>
      Toute contestation portant sur la validité́, l’exécution, l’interprétation ou la
      formation des CGU (Conditions Générales d’Utilisation) qui n’aurait pas été́
      résolue par voie amiable conformément au paragraphe précédent sera soumise à la
      juridiction du Tribunal de Commerce d’Abidjan.
    </ViewParagraph>
    <ViewH2>13. INFORMATIONS LEGALES</ViewH2>
    <ViewParagraph>
      <strong>Editeur du Service</strong>
    </ViewParagraph>
    <ViewParagraph>
      Le Site est édité́ par la{' '}
      <strong>
        Banque Internationale pour le Commerce et l’Industrie de la Côte d’Ivoire,
        par abréviation « BICICI »,
      </strong>{' '}
      Société Anonyme au capital de Seize Milliards Six Cent Soixante Six Millions
      Six Cent Soixante Dix Mille (16.666.670.000) de francs CFA dont le siège social
      est à Abidjan – Avenue Franchet d’Esperey, 01 BP 1298 Abidjan 01, immatriculée
      au Registre du Commerce et du Crédit Mobilier d’Abidjan sous le numéro
      CI-ABJ-1962-B-547
    </ViewParagraph>
    <ViewParagraph>
      <strong>Directeur de la publication</strong>
    </ViewParagraph>
    <ViewParagraph>
      Le responsable de la publication du Site est Emma Carine YAO.
    </ViewParagraph>
    <ViewParagraph>
      <strong>Contact et réclamations</strong>
    </ViewParagraph>
    <ViewParagraph>
      Toute interrogation ou réclamation relative au Site ou aux CGU (Conditions
      Générales d’Utilisation), autre que celles portées sur les données à caractère
      personnel, peut être adressée à amoinemmacarine.yao@bicici.ci ou
      hugues.kampe@finafrique.com
    </ViewParagraph>
    <ViewParagraph>
      Pour les réclamations ou demandes relatives à vos données personnelles,
      veuillez contacter directement le Correspondant à la Protection des Données
      Personnelles à l’adresse ci-après : paul.kouame@bicici.ci
    </ViewParagraph>
    <ViewParagraph>
      <strong>Hébergeur du Service (FINAFRIQUE IT)</strong>
    </ViewParagraph>
    <ViewParagraph>
      Le Site est hébergé́ au sein de l’Union Européenne sur la plateforme opérée par
      FINAFRIQUE SAS, société anonyme inscrite au RCS de Paris sous le numéro SIREN
      848 430 252, au capital social de 1 567 000 EUR dont l’adresse est située 140
      Bis rue de Rennes -75006 Paris.
    </ViewParagraph>
    <br />
  </Fragment>
);

export default CGU;
