import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, quote, pathName, hashtag, banner }) => (
  <StaticQuery
    query={graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            shortName
            defaultQuote: quote
            defaultHashtag: hashtag
            author
            postTitleSeo
            siteLanguage
            defaultBanner: banner
            siteUrl
            defaultDescription: description
            defaultBanner: banner
            twitter
          }
        }
      }
    `}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          shortName,
          author,
          postTitleSeo,
          defaultHashtag,
          defaultQuote,
          siteLanguage,
          defaultBanner,
          siteUrl,
          defaultDescription,
          twitter,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        quote: quote || defaultQuote,
        hashtag: hashtag || defaultHashtag,
        banner: banner || defaultBanner,
        url: `${siteUrl}${pathName || '/'}`,
      };

      return (
        <Helmet>
          <html lang={siteLanguage} />
          <title>{`${seo.title} - ${postTitleSeo}`}</title>
          <meta property="author" content={author} />
          <meta property="title" content={`${seo.title} - ${postTitleSeo}`} />
          <meta name="description" content={seo.description} />
          <meta property="quote" content={seo.quote} />
          <meta name="image" content={seo.banner} />

          <meta name="apple-mobile-web-app-title" content={shortName} />
          <meta name="application-name" content={shortName} />

          <meta name="robots" content="noodp" />

          {/* OpenGraph  */}
          <meta property="og:url" content={seo.url} />
          <meta property="og:title" content={`${seo.title} - ${postTitleSeo}`} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.banner} />
          <meta property="og:locale" content={siteLanguage} />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content={shortName} />
          <meta property="og:quote" content={seo.quote} />
          <meta property="og:hashtag" content={seo.hashtag} />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={twitter} />
          <meta name="twitter:title" content={`${seo.title} - ${postTitleSeo}`} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.banner} />
        </Helmet>
      );
    }}
  />
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  quote: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  banner: null,
  quote: null,
  pathname: null,
};
